import React from 'react'
import './TopNav.css'

const TopNav = () => {
  return (
  <div className='top_wrapper'>
    
    <a href="" className='free_ship'>Free Delivery upon $300</a>
    {/*
    <div className='top_btn'>
      <a href="" >Lang</a>
    </div>
    
    <div className='top_btn'>
      <a href="">Store</a>
    </div>
  */}
  </div>
  )
}

export default TopNav