import React, { useEffect, useState } from 'react'
import {AiOutlineShoppingCart, AiOutlineHeart} from 'react-icons/ai'
import {CgProfile} from 'react-icons/cg'
import './NavProfile.css'

const NavProfile = ({quantity, exp}) => {


  return (
    <div className='nav_icon'>
        <div className='personal'>
            <a href="">
                <AiOutlineHeart/>
            </a>
            <p className='cart_count'>1</p>
            <a  href={(exp||window.location.pathname==='/account')?"/account":"/account/login"} className='middle_icon' ><CgProfile/></a>
            <a href="/cart">
                <AiOutlineShoppingCart/>
            </a>
            <p className='cart_count'>{quantity}</p>
        </div>

        {<div className='order'>
            <a href="/account/order">Re-Order</a>

        </div>}
    </div>
  )
}

export default NavProfile